import React, { useState } from 'react';
import { httpClient } from '../../httpClient';
import UserResults from '../user-results/user-results';
import * as classes from './search.module.css';
import { Combobox, TextField, Button, Tooltip, Message } from '@watts/design-system-react';
import { useUser } from '../../user-context';
import { Environments } from '../../types/environments';
import { Opcos } from '../../types/opcos';
import { Areas } from '../../types/areas';
import { Regions } from '../../types/regions';
import { Teams } from '../../types/teams';
import { UserTypes } from '../../types/user-types';
export default function Search() {
    const { requestedBy } = useUser();
    const [areaSelected, setAreaSelected] = useState(Areas[0]);
    const [regionSelected, setRegionSelected] = useState(Regions[0]);
    const [envSelected, setEnvSelected] = useState(Opcos[0]);
    const [opcoSelected, setOpcoSelected] = useState(Opcos[0]);
    const [userTypeSelected, setUserTypeSelected] = useState(UserTypes[0]);
    const [label, setLabel] = useState('');
    const [accountId, setAccountId] = useState('');
    const [ownerTeam, setOwnerTeam] = useState('');
    const [email, setEmail] = useState('');
    const [results, setResults] = useState([]);
    const [description, setDescription] = useState('');
    const environments = Environments.map(env => {
        return { value: env, label: env };
    });
    const opcos = Opcos.map(opco => {
        return { value: opco, label: opco };
    });
    const areas = Areas.map(area => {
        return { value: area, label: area };
    });
    const regions = Regions.map(region => {
        return { value: region, label: region };
    });
    const teams = Teams.map(team => {
        return { value: team, label: team };
    });
    const userTypes = UserTypes.map(userType => {
        return { value: userType, label: userType };
    });
    const handleValueChange = (name, value) => {
        switch (name) {
            case 'area':
                setEnvSelected(Environments[0]);
                setRegionSelected(Regions[0]);
                setOpcoSelected(Opcos[0]);
                setAreaSelected(value);
                break;
            case 'region':
                setRegionSelected(value);
                break;
            case 'env':
                setEnvSelected(value);
                break;
            case 'opco':
                setOpcoSelected(value);
                break;
            case 'userType':
                setUserTypeSelected(value);
                break;
        }
    };
    function onOpcoChange(selectedOption) {
        setOpcoSelected(selectedOption.value);
    }
    function onAreaChange(selectedOption) {
        setAreaSelected(selectedOption.value);
    }
    async function search() {
        const encodedLabel = encodeURIComponent(label);
        const encodedAccountId = encodeURIComponent(accountId);
        const encodedEmail = encodeURIComponent(email);
        const encodedOwnerTeam = encodeURIComponent(ownerTeam);
        const encodedDescription = encodeURIComponent(description);
        const region = regionSelected === Regions[0] ? '' : regionSelected;
        const env = envSelected === Environments[0] ? '' : envSelected;
        const opco = opcoSelected === Opcos[0] ? '' : opcoSelected;
        const area = areaSelected === Areas[0] ? '' : areaSelected;
        const response = await httpClient.getUsers(requestedBy, area, region, env, opco, encodedLabel, encodedAccountId, encodedOwnerTeam, encodedEmail, encodedDescription, userTypeSelected);
        setResults(response.data);
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        await search();
    };
    return (React.createElement("div", { "data-testid": 'search' },
        React.createElement(Message, { description: "This app does not create, edit or delete users in other Digital Factory apps. It serves as a centralized repository of test data, using it's own database. Users still need to be created in other apps. After that, they can be added here to be used in test frameworks that are connected to the API, or to be easily accessed by other Digital Factory members. Please report issues to the Core Automation Team." }),
        React.createElement("br", null),
        React.createElement("form", { onSubmit: handleSubmit },
            React.createElement("div", { className: classes.container },
                React.createElement("div", { className: classes.comboboxContainer },
                    React.createElement(Combobox, { label: 'Select the area', options: areas, defaultSelectedOption: areas[0], onValueChange: (value) => handleValueChange('area', value), "data-testid": 'area-select', noMatchLabel: '' }),
                    areaSelected === 'AX' && React.createElement(Combobox, { label: 'Select the region', options: regions, defaultSelectedOption: regions[0], onValueChange: (value) => handleValueChange('region', value), "data-testid": 'region-select', noMatchLabel: '' }),
                    areaSelected !== Areas[0] && React.createElement(Combobox, { label: 'Select the environment', options: environments, defaultSelectedOption: environments[0], onValueChange: (value) => handleValueChange('env', value), "data-testid": 'env-select', noMatchLabel: '' }),
                    areaSelected === 'CX' &&
                        React.createElement(Tooltip, { label: 'Input is editable. Delete the value to select another option.', placement: 'top-right' },
                            React.createElement(Combobox, { label: 'Select a OpCo', options: opcos, defaultSelectedOption: opcos[0], onValueChange: (value) => handleValueChange('opco', value), "data-testid": 'opco-select', autocomplete: true, noMatchLabel: '' })),
                    React.createElement(Combobox, { label: 'User Type', name: 'userType', options: userTypes, defaultSelectedOption: userTypes[0], onValueChange: (value) => handleValueChange('userType', value), "data-testid": 'user-type', noMatchLabel: '' }))),
            React.createElement("div", { className: classes.inputContainer },
                React.createElement(TextField, { label: 'Label', name: 'label', textFieldSize: 's', type: 'text', value: label, "data-testid": 'label', onChange: (e) => setLabel(e.target.value) }),
                React.createElement(TextField, { label: 'Email', name: 'email', textFieldSize: 's', type: 'text', value: email, "data-testid": 'email', onChange: (e) => setEmail(e.target.value) }),
                React.createElement(TextField, { label: 'Account Id', name: 'account-id', textFieldSize: 's', type: 'text', value: accountId, "data-testid": 'accountId', onChange: (e) => setAccountId(e.target.value) }),
                React.createElement(Combobox, { label: 'Owner team', name: 'ownerTeam', options: teams, defaultSelectedOption: teams[0], onValueChange: (value) => setOwnerTeam(value || ''), "data-testid": 'ownerTeam', noMatchLabel: '' }),
                React.createElement(TextField, { label: 'Description', name: 'description', textFieldSize: 's', type: 'text', value: description, "data-testid": 'description', onChange: (e) => setDescription(e.target.value) }),
                React.createElement(Button, { variant: 'primary', type: 'submit' }, "Search"))),
        results.length > 0 && React.createElement(UserResults, { data: results })));
}
