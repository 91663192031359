import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { msalInstance } from './msalInstance';
import { BrowserRouter as Router, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import Logo from './public/logo.svg';
import Pages from './components/pages/pages';
import AddUser from './components/add-user/add-user';
import { loginRequest } from './authConfig';
import Search from './components/search/search';
import { WattsProvider } from '@watts/design-system-react';
const MsalProvider = React.lazy(() => import('@azure/msal-react').then(module => ({ default: module.MsalProvider })));
const MsalAuthenticationTemplate = React.lazy(() => import('@azure/msal-react').then(module => ({ default: module.MsalAuthenticationTemplate })));
import '@watts/css';
import EditUser from './components/edit-user/edit-user';
import { UserProvider } from './user-context';
import { InteractionType } from '@azure/msal-browser';
const container = document.getElementById('root'); // Get the root element.
const root = createRoot(container);
const styles = {
    mainContainer: {
        fontFamily: 'sans-serif',
        padding: '20px',
    },
    heading: {
        color: '#27348B',
        fontSize: '24px',
    },
};
function AppContent() {
    const navigate = useNavigate();
    function onChangeTab(pageType) {
        navigate(pageType);
    }
    return (React.createElement(MsalAuthenticationTemplate, { interactionType: InteractionType.Redirect, authenticationRequest: loginRequest },
        React.createElement("div", { style: styles.mainContainer },
            React.createElement("img", { src: Logo, alt: "logo", style: { maxHeight: '30px', cursor: 'pointer' }, onClick: () => navigate('/') }),
            React.createElement(Pages, { onChangeTab: onChangeTab }),
            React.createElement(Routes, null,
                React.createElement(Route, { path: "/", element: React.createElement(Navigate, { to: "/user-search" }) }),
                React.createElement(Route, { path: "/user-search", element: React.createElement(Search, null) }),
                React.createElement(Route, { path: "/add-user", element: React.createElement(AddUser, null) }),
                React.createElement(Route, { path: "/edit-user", element: React.createElement(EditUser, null) })))));
}
export default function App() {
    return (React.createElement(WattsProvider, null,
        React.createElement(MsalProvider, { instance: msalInstance },
            React.createElement(UserProvider, null,
                React.createElement(Router, null,
                    React.createElement(AppContent, null))))));
}
root.render(React.createElement(App, null));
