import axios from 'axios';
export class httpClient {
    static token;
    static apiUrl = process.env.NODE_ENV === 'development'
        ? `http://localhost:${process.env.PORT}/qa-api`
        : `https://qa-ms.spark.sonepar.com/qa-api`;
    static expiresAt;
    static async getToken() {
        const now = new Date().getTime();
        let expiresIn;
        const response = await axios.post(`${this.apiUrl}/auth`, {
            username: process.env.API_USER_USERNAME,
            pwd: process.env.API_USER_PWD,
        }).catch((err) => {
            console.log('API call failed with following error : ');
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
            console.log(err.message);
            console.log(err.request);
        });
        this.token = response?.data.token;
        expiresIn = 3600000; // 1 hour
        this.expiresAt = now + expiresIn;
    }
    static async getUsers(requestedBy, area, region, env, opco, encodedLabel, encodedAccountId, encodedOwnerTeam, encodedEmail, encodedDescription, userType) {
        if (!this.token || this.expiresAt < new Date().getTime()) {
            await this.getToken();
        }
        return axios.get(`${this.apiUrl}/v2/users`, {
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'X-Requested-By': requestedBy
            },
            params: {
                envName: env,
                opco: opco,
                label: encodedLabel,
                accountId: encodedAccountId,
                ownerTeam: encodedOwnerTeam,
                email: encodedEmail,
                description: encodedDescription || '',
                area: area,
                region: region,
                type: userType
            }
        });
    }
    static async createUser(requestedBy, userInfo) {
        if (!this.token || this.expiresAt < new Date().getTime()) {
            await this.getToken();
        }
        return axios.post(`${this.apiUrl}/v2/users`, userInfo, {
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'X-Requested-By': requestedBy
            }
        });
    }
    static async updateUser(requestedBy, userInfo) {
        if (!this.token || this.expiresAt < new Date().getTime()) {
            await this.getToken();
        }
        return axios.put(`${this.apiUrl}/v2/users`, userInfo, {
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'X-Requested-By': requestedBy
            }
        });
    }
    static async deleteUser(requestedBy, _id) {
        if (!this.token || this.expiresAt < new Date().getTime()) {
            await this.getToken();
        }
        return axios.delete(`${this.apiUrl}/v2/users/${_id}`, {
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'X-Requested-By': requestedBy
            }
        });
    }
}
