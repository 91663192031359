import React, { useState } from 'react';
import * as classes from './show-text.module.css';
import { Icon } from '@watts/design-system-react';
export default function ShowText({ text }) {
    const [textDisplayed, setTextDisplayed] = useState(false);
    function toggleState() {
        setTextDisplayed(!textDisplayed);
    }
    return (React.createElement("div", { "data-testid": 'showText', className: classes.container, onClick: toggleState },
        React.createElement("p", { className: classes.text }, textDisplayed ? text : '********'),
        textDisplayed ? React.createElement(Icon, { icon: 'eye-closed' }) : React.createElement(Icon, { icon: 'eye-open' })));
}
