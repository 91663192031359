export const Teams = [
    '',
    // CX
    'CX',
    'E&C',
    'M&P',
    'B&D',
    'S&P',
    'AFS',
    'LPS',
    'Marketplace',
    'DPC',
    'E-Proc',
    'Mobile',
    // AX
    'AX',
    'AX Bridge',
    'AX Sales',
    'AX Marketing',
    'AX CC',
    // Others
    'Data',
    'Integration'
];
