import * as React from 'react';
import { useLocation } from 'react-router-dom';
import UserForm from '../user-form/user-form';
import { Message } from '@watts/design-system-react';
import { useNavigate } from 'react-router-dom';
export default function AddUser() {
    const { state } = useLocation();
    const initialUserInfo = state?.initialUserInfo;
    const navigate = useNavigate();
    return (React.createElement("div", null,
        React.createElement(Message, { description: "This app does not create, edit or delete users in other Digital Factory apps. It serves as a centralized repository of test data, using it's own database. Users still need to be created in other apps. After that, they can be added here to be used in test frameworks that are connected to the API, or to be easily accessed by other Digital Factory members. Please report issues to the Core Automation Team." }),
        React.createElement(UserForm, { initialUserInfo: initialUserInfo, onSubmitSuccess: () => {
                navigate('/search');
            } })));
}
