import React, { useEffect, useState } from 'react';
import { PageTypes } from '../../types/page-types';
import * as classes from './pages.module.css';
export default function Pages({ onChangeTab }) {
    const [selectedTab, setSelectedTab] = useState(PageTypes['SEARCH']);
    useEffect(() => {
        onChangeTab(selectedTab);
    }, [selectedTab]);
    return (React.createElement("div", null,
        React.createElement("div", { className: classes.tabs, "data-testid": 'pages' },
            React.createElement("div", { "data-testid": 'search', className: `${classes.tab} ${selectedTab === PageTypes.SEARCH ? classes.selectedTab : ''}`, onClick: () => setSelectedTab(PageTypes.SEARCH) }, "User search"),
            React.createElement("div", { "data-testid": 'addUser', className: `${classes.tab} ${selectedTab === PageTypes.ADD_USER ? classes.selectedTab : ''}`, onClick: () => setSelectedTab(PageTypes.ADD_USER) }, "Add user"))));
}
