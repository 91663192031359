import React, { createContext, useContext, useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
const UserContext = createContext({ requestedBy: '' });
export const UserProvider = ({ children }) => {
    const { accounts } = useMsal();
    const [email, setEmail] = useState('');
    useEffect(() => {
        if (accounts.length > 0) {
            const account = accounts[0];
            setEmail(account.username);
        }
    }, [accounts]);
    return (React.createElement(UserContext.Provider, { value: { requestedBy: email } }, children));
};
export const useUser = () => useContext(UserContext);
