import React, { useState } from 'react';
import { Button } from '@watts/design-system-react';
export default function CopyToClipboard({ text }) {
    const [copied, setCopied] = useState(false);
    const handleCopy = () => {
        navigator.clipboard.writeText(text).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        }).catch((error) => {
            console.error('Failed to copy to clipboard', error);
        });
    };
    return (React.createElement(Button, { onClick: handleCopy, icon: copied ? 'check' : 'copy', "data-testid": 'copyToClipboard', variant: 'ghost' }));
}
;
